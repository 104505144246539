import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input, OnInit,
} from '@angular/core';
import {ContentStore} from "../content.store";
import {ContentType} from "../content.interface";
import {ContentCardComponent} from "../content-card/content-card.component";
import {ContentTypePipe} from "../pipes/content-type.pipe";
import {ActivatedRoute, ParamMap, RouterLink} from "@angular/router";
import {ListUrlPipe} from "../pipes/list-url.pipe";
import {PaginationComponent} from "../../ui-kit/components/pagination/pagination.component";
import {User} from "../../user/user";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-content-list',
  standalone: true,
  imports: [
    ContentCardComponent,
    ContentTypePipe,
    RouterLink,
    ListUrlPipe,
    PaginationComponent,
    NgIf
  ],
  templateUrl: './content-list.component.html',
  styleUrl: './content-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentListComponent implements OnInit{
  private _store = inject(ContentStore);
  // private _route = inject(ActivatedRoute);

  title = input.required<string>({ alias: 'title'});
  showPagination = input<boolean>(false, { alias: 'showPagination' });
  showContentTypeFilter = input<boolean>(false, { alias: 'showContentTypeFilter' });
  contentType = input<ContentType|null>(null, { alias: 'contentType' });
  author = input<User|null>(null);
  displayViewMore = false;

  public readonly currentContentType = this._store.query.contentType;

  readonly entities = this._store.entities

  readonly query = this._store.query;

  // private _route = inject(ActivatedRoute);

  public contentTypes: ContentType[] = [
    ContentType.poem,
    ContentType.story,
    ContentType.article,
    ContentType.feuilleton,
    // ContentType.book,
  ];

  private _route = inject(ActivatedRoute);

  ngOnInit() {
    this._store.withAuthor(this.author()?._id);
    this._store.withContentType(this.contentType());
    this.handleParamsChange(this._route.snapshot.queryParamMap)

    this._route.queryParamMap.subscribe(params => {
      this.handleParamsChange(params)
    });
  }

  handleParamsChange(params: ParamMap) {
    const page = params.get('page') || '1';
    const limit = params.get('limit') || '12';

    this._store.withPagination(parseInt(page), parseInt(limit)).then(() => {
      this._store.load();
    });
  }

  filterContentType(param: ContentType|null) {
    this._store.withContentType(param);
    this._store.withPagination(1).then(() => {
      this._store.load();
    })
  }

  changePage($event: number, el: HTMLElement) {
    this._store.withPagination($event).then(() => {
      this._store.load();
      el.scrollIntoView({behavior: 'smooth'});
    });
  }
}
