import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'cutText',
  standalone: true
})
export class CutTextPipe implements PipeTransform {

  transform(value: string, length: number): any {
    if (value == null) {
      return null;
    }

    return value
      .replace(/(<([^>]+)>)/gi, "")
      .substring(0, length) + '...';
  }

}
