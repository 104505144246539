import { Pipe, PipeTransform } from '@angular/core';
import {AuthorInterface, User} from "../user";

@Pipe({
  name: 'profileUrl',
  standalone: true
})
export class ProfileUrlPipe implements PipeTransform {

  transform(value: AuthorInterface|User|null): string|any[] {
    if (!value) {
      return '#'
    }

    return ['/uzytkownik', value.username.toLowerCase(), 'profil'];
  }

}
