import {Component, input} from '@angular/core';
import {ContentInterface} from "../content.interface";
import {RouterLink} from "@angular/router";
import {DatePipe, DecimalPipe, NgIf, NgOptimizedImage, SlicePipe} from "@angular/common";
import {AvatarPathPipe} from "../../user/pipes/avatar-path.pipe";
import {DisplayNamePipe} from "../../user/pipes/display-name.pipe";
import {CutTextPipe} from "../../ui-kit/pipes/cut-text.pipe";
import {StripHtmlPipe} from "../../ui-kit/pipes/strip-html.pipe";
import {ContentTypePipe} from "../pipes/content-type.pipe";
import {ContentUrlPipe} from "../pipes/content-url.pipe";
import {ProfileUrlPipe} from "../../user/pipes/profile-url.pipe";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-content-card',
  standalone: true,
  imports: [
    RouterLink,
    NgOptimizedImage,
    AvatarPathPipe,
    DisplayNamePipe,
    NgIf,
    SlicePipe,
    CutTextPipe,
    StripHtmlPipe,
    DatePipe,
    DecimalPipe,
    ContentTypePipe,
    ContentUrlPipe,
    ProfileUrlPipe,
    MatIcon
  ],
  templateUrl: './content-card.component.html',
  styleUrl: './content-card.component.scss'
})
export class ContentCardComponent {
  entity = input.required<ContentInterface>({alias: 'entity'});
}
