<div class="content-card">
  <a [routerLink]="entity()|contentUrl" [attr.aria-label]="entity().title"></a>
  <div class="content-card-container">
    <div class="content-card-content">
      <div class="content">
        <div class="badge-list">
          <p class="badge">
            {{ entity().content_type|contentType }}
          </p>
        </div>
        <h2>
          <a [attr.title]="entity().title" [routerLink]="entity()|contentUrl">
            {{  entity().title }}
          </a>
        </h2>

        <div class="content-description">
          <p>{{ entity().content|stripHtml|cutText:100 }}</p>
        </div>
      </div>

      <div class="content-card-meta">
        <a [routerLink]="entity().author|profileUrl">
          <div class="avatar">
            <img disableOptimizedSrcset [fill]="true" [loaderParams]="{'preset': 'avatar-small'}" [ngSrc]="entity().author|avatarPath" role="none" [loading]="'lazy'">
          </div>
          <span class="author_name">{{  entity().author|displayName }}</span>
        </a>
        <span class="separator">.</span>
        <span class="date">{{ entity().published_at|date:'dd.MM.yyyy HH:mm' }}</span>
      </div>
    </div>

    <div class="content-card-meta actions">
      <div class="info">
        <button
          title="Liked">
          <mat-icon [svgIcon]="'heroicons_outline:heart'"></mat-icon>
          <span>{{ entity().votes_average|number:'1.2'}}</span>
        </button>
        <a
          title="Comments" [routerLink]="entity()|contentUrl" [fragment]="'comments'">
          <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-bottom-center-text'"></mat-icon>
          <span>{{ entity().comments_count }}</span>
        </a>
      </div>
<!--      <div-->
<!--        class="bookmarks">-->
<!--        <button-->
<!--          title="Save to reading list">-->
<!--          <mat-icon [svgIcon]="'heroicons_outline:bookmark'"></mat-icon>-->
<!--        </button>-->
<!--      </div>-->
    </div>

  </div>
</div>
