import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'stripHtml',
  standalone: true
})
export class StripHtmlPipe implements PipeTransform {
  transform(value: string): any {
    return value.replace(/(<([^>]+)>)/gi, "");
  }
}
