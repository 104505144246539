<div class="content-list" #top>
  <div>
    <div class="heading">
      <div class="max-w-2xl">
        <h2>{{ title() }}</h2>
      </div>
    </div>
    @if (showContentTypeFilter()) {
      <div class="flex justify-between">
        <nav class="content-type-selector">
          <ul>
            <li>{{ contentType() | contentType }}</li>
            <li [class.selected]="currentContentType() == null">
              <button (click)="filterContentType(null)">Wszystkie</button>
            </li>
            @for (type of contentTypes; track type) {
              <li [class.selected]="currentContentType() == type">
                <button (click)="filterContentType(type)">{{ type|contentType:true }}</button>
              </li>
            }
          </ul>
        </nav>

        <a *ngIf="displayViewMore" class="view-more !hidden md:!flex" [routerLink]="currentContentType()! | listUrl">
          <span>Zobacz więcej</span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true"
               data-slot="icon" class="w-6 h-6 ml-3">
            <path fill-rule="evenodd"
                  d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                  clip-rule="evenodd"></path>
          </svg>
        </a>

      </div>
    }
  </div>

  <div>
    <div class="entity-list">
      @for (entity of entities(); track entity.slug) {
        <app-content-card [entity]="entity"></app-content-card>
      }
    </div>

    <div *ngIf="entities().length == 0" class="mx-auto mt-6 empty-state">
      <p>Nie znaleziono żadnych utworów</p>
    </div>

  </div>
</div>

@if (showPagination()) {
  <app-pagination (onPageChange)="changePage($event, top)" [limit]="query().limit" [count]="query().count"
                  [current]="query().page"></app-pagination>
}
